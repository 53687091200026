<template>
  <div>
    <v-card :loading="loading">
      <v-toolbar flat>
        <v-icon> fas fa-store</v-icon>
        <v-toolbar-title class="pl-4">Canais de Venda</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="fechar">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row no-gutters>
          <v-col xs="12" sm="12" md="10" lg="5" xl="5">
            <v-text-field v-model="searchQuery" label="Buscar" dense outlined></v-text-field>
          </v-col>
          <v-col :cols="12">
            <v-data-table
              :headers="headers"
              hide-default-footer
              :items="filteredData"
              :items-per-page="1000000"
              class="elevation-1"
            >
            <template #item.cadastrado_em="{ item }">
              {{ formatData(item.cadastrado_em) }}
            </template>

            <template #item.status="{ item }">
              <v-chip
                v-if="item.ativo"
                color="success"
                label
                small
                outlined
              >Ativo</v-chip>
              <v-chip
                v-else
                color="error"
                label
                small
                outlined
              >Inativo</v-chip>
            </template>
          </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import { baseApiUrl, showError } from "@/global";
export default {
  data() {
    return {
      dados: [],
      loading: false,
      searchQuery: '',
      headers: [
        { text: "ID", value: "idcanal_vendas", sortable: false },
        { text: "Nome", value: "nome", sortable: false },
        { text: "Data do Cadastro", value: "cadastrado_em", sortable: false },
        { text: "Status", value:"status", sortable: false },
      ]
    }
  },

  computed: {
    filteredData() {
      if (this.dados) {
        if (!this.searchQuery) {
          return this.dados
        }

        const query = this.searchQuery.toLowerCase()
        return this.dados.filter(item => {
          return (
            (item.nome?.toLowerCase().includes(query) ?? false)
          )
        })
      } else {
        return []
      }
    }
  },

  methods: {
    formatData(val) {
      return val ? moment(val).format("DD/MM/YYYY") : ""
    },

    fechar() {
      this.$router.push("/")
    },

    carregarDados () {
      this.loading = true
      axios.get(`${baseApiUrl}/canal-venda`)
        .then(res => {
          this.dados = [...res.data.dados]
          this.loading = false
        })
        .catch(() => {
          showError('Erro ao carregar os dados!!')
          this.loading = false
        })
    }
  },

  mounted () {
    this.carregarDados()
  }
}
</script>

<style scoped></style>